/* eslint-disable react/jsx-one-expression-per-line */
import React, { ReactElement, useEffect, useState } from 'react';

import { Button, ButtonType } from '@components/Button';
import {
  BulkPricingAdjustment,
  BulkPricingPercent,
  BulkPricingPrice,
  isBulkPricingPercent,
  isBulkPricingPrice,
  Variant,
} from '@interfaces/Product';
import { CapitaliseSentence } from '@lib/formatText';
import {
  getIndexedDBStoreData,
  Stores,
  updateIndexedDBData,
} from '@lib/localData';
import { logBreadcrumb } from '@lib/utils';

enum BulkBuyView {
  PricePerUnit = 0,
  TotalPriceAllUnits = 1,
  PricePerServe = 2,
  DiscountPerUnit = 3,
  TotalDiscountAllUnits = 4,
  PercentageSaving = 5,
}

enum PackageDescription {
  Bags = 'bags',
  Capsules = 'capsules',
  Shakers = 'shakers',
  Packs = 'packs',
  Shirts = 'shirts',
}

interface Option {
  price: number;
  volume?: number;
  unit?: string;
  bulkPricing?: (
    | BulkPricingPrice
    | BulkPricingPercent
    | BulkPricingAdjustment
  )[];
}

function getTotalPriceAllUnitsView(
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment,
  option: Option
): string {
  const basePrice = option.price / 100;
  let main = '';
  //  Fixed Price
  if (isBulkPricingPrice(pricing)) {
    main = (pricing.minimumQuantity * pricing.price).toFixed(2);
  }
  // Percentage off
  if (isBulkPricingPercent(pricing)) {
    main = (
      ((100 - pricing.percentOff) * option.price * pricing.minimumQuantity) /
      10000
    ).toFixed(2);
  }
  //  Price Adjustment
  if (!isBulkPricingPrice(pricing) && !isBulkPricingPercent(pricing)) {
    main = (
      pricing.minimumQuantity *
      (basePrice - pricing.priceAdjustment)
    ).toFixed(2);
  }
  return `$${main}`;
}

function getPricePerServeView(
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment,
  option: Option,
  servingSize: number
) {
  const basePrice = option.price / 100;
  const standardNumberOfServingsPerPackage =
    option.unit === 'kg'
      ? Math.floor(((option.volume as number) * 1000) / servingSize)
      : Math.floor((option.volume as number) / servingSize);

  const numberOfServings =
    standardNumberOfServingsPerPackage * pricing.minimumQuantity;

  let main = '';
  //  Fixed Price
  if (isBulkPricingPrice(pricing)) {
    main = (
      (pricing.minimumQuantity * pricing.price) /
      numberOfServings
    ).toFixed(2);
  }
  // Percentage off
  if (isBulkPricingPercent(pricing)) {
    main = (
      ((100 - pricing.percentOff) * option.price * pricing.minimumQuantity) /
      10000 /
      numberOfServings
    ).toFixed(2);
  }
  //  Price Adjustment
  if (!isBulkPricingPrice(pricing) && !isBulkPricingPercent(pricing)) {
    main = ((basePrice - pricing.priceAdjustment) / numberOfServings).toFixed(
      2
    );
  }
  return `$${main} per serve`;
}

function getDiscountPerUnitView(
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment,
  option: Option,
  packageDescription?: string
) {
  const basePrice = option.price / 100;
  let main = '';
  //  Fixed Price
  if (isBulkPricingPrice(pricing)) {
    main = (basePrice - pricing.price).toFixed(2);
  }
  // Percentage off
  if (isBulkPricingPercent(pricing)) {
    main = (((100 - pricing.percentOff) * option.price) / 10000).toFixed(2);
  }
  //  Price Adjustment
  if (!isBulkPricingPrice(pricing) && !isBulkPricingPercent(pricing)) {
    main = pricing.priceAdjustment.toFixed(2);
  }

  let suffix = '';
  if (packageDescription === PackageDescription.Bags) {
    suffix = ' per bag';
  }
  return `Save $${main}${suffix}`;
}

function getTotalDiscountAllUnitsView(
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment,
  option: Option,
  packageDescription?: string
) {
  const basePrice = option.price / 100;
  let main = '';
  //  Fixed Price
  if (isBulkPricingPrice(pricing)) {
    main = (pricing.minimumQuantity * (basePrice - pricing.price)).toFixed(2);
  }
  // Percentage off
  if (isBulkPricingPercent(pricing)) {
    main = (
      ((100 - pricing.percentOff) * pricing.minimumQuantity * option.price) /
      10000
    ).toFixed(2);
  }
  //  Price Adjustment
  if (!isBulkPricingPrice(pricing) && !isBulkPricingPercent(pricing)) {
    main = (pricing.minimumQuantity * pricing.priceAdjustment).toFixed(2);
  }

  let suffix = '';
  if (packageDescription === PackageDescription.Bags) {
    suffix = ` for ${pricing.minimumQuantity} bags`;
  }
  return `Save $${main}${suffix}`;
}

function getPercentageSavingView(
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment,
  option: Option
) {
  const basePrice = option.price / 100;
  let main = '';
  //  Fixed Price
  if (isBulkPricingPrice(pricing)) {
    main = (((basePrice - pricing.price) * 100) / basePrice).toFixed(1);
  }
  // Percentage off
  if (isBulkPricingPercent(pricing)) {
    main = (pricing.percentOff * pricing.minimumQuantity).toFixed(1);
  }
  //  Price Adjustment
  if (!isBulkPricingPrice(pricing) && !isBulkPricingPercent(pricing)) {
    main = ((100 * pricing.priceAdjustment) / basePrice).toFixed(1);
  }
  return `Save ${main}%`;
}

function getPricePerUnitView(
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment,
  option: Option
) {
  const basePrice = option.price / 100;
  let main = '';
  //  Fixed Price
  if (isBulkPricingPrice(pricing)) main = pricing.price.toFixed(2);
  // Percentage off
  if (isBulkPricingPercent(pricing))
    main = (((100 - pricing.percentOff) * option.price) / 10000).toFixed(2);
  //  Price Adjustment
  if (!isBulkPricingPrice(pricing) && !isBulkPricingPercent(pricing))
    main = (basePrice - pricing.priceAdjustment).toFixed(2);
  return `$${main} each`;
}

function getStandardPricing(
  bulkBuyView: number,
  option: Option,
  servingSize?: number,
  packageDescription?: string
) {
  // standard pricing per serve
  if (bulkBuyView === BulkBuyView.PricePerServe && servingSize) {
    const basePrice = option.price / 100;
    const volume =
      option.unit === 'kg'
        ? (option.volume as number) * 1000
        : (option.volume as number);
    const numberOfServings = Math.floor(volume / servingSize);
    return (
      <li className="m-0 p-0">
        {/* add a space between number and unit if it's bag, and remove if it's capsules */}
        {option.volume}
        {packageDescription === PackageDescription.Bags ? '' : ' '}
        {option.unit} = ${(basePrice / numberOfServings).toFixed(2)} per serve
      </li>
    );
  }
  // all other cases
  return (
    <li className="m-0 p-0">
      {option.volume}
      {option.unit === 'g' || option.unit === 'kg' ? '' : ' '}
      {option.unit} = ${(option.price / 100).toFixed(2)}
    </li>
  );
}

// get bulk pricing based on BulkBuyView
function getBulkPricingByType({
  pricing,
  option,
  bulkBuyView,
  servingSize,
  packageDescription,
}: {
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment;
  option: Option;
  bulkBuyView: number;
  servingSize?: number;
  packageDescription?: string;
}) {
  // each view has to handle 3 cases in Big Commerce:  Fixed Price || Percentage off || Price Adjustment
  if (bulkBuyView === BulkBuyView.TotalPriceAllUnits) {
    return getTotalPriceAllUnitsView(pricing, option);
  } else if (bulkBuyView === BulkBuyView.PricePerServe && servingSize) {
    return getPricePerServeView(pricing, option, servingSize);
  } else if (bulkBuyView === BulkBuyView.DiscountPerUnit) {
    return getDiscountPerUnitView(pricing, option, packageDescription);
  } else if (bulkBuyView === BulkBuyView.TotalDiscountAllUnits) {
    return getTotalDiscountAllUnitsView(pricing, option, packageDescription);
  } else if (bulkBuyView === BulkBuyView.PercentageSaving) {
    return getPercentageSavingView(pricing, option);
  } else {
    //PRICE PER UNIT is the fall back case
    return getPricePerUnitView(pricing, option);
  }
}

function BagsBulkPriceItem({
  pricing,
  option,
  packageDescription,
  servingSize,
  bulkBuyView,
}: {
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment;
  option: Option;
  packageDescription: string;
  servingSize: number;
  bulkBuyView: number;
}) {
  return (
    <li className="m-0 p-0">
      {/* calculate bulk quantity */}
      {pricing.minimumQuantity * (option.volume as number) >= 1000 &&
      option.unit === 'g'
        ? (pricing.minimumQuantity * (option.volume as number)) / 1000
        : pricing.minimumQuantity * (option.volume as number)}
      {/* switch unit from g to kg if needed */}
      {pricing.minimumQuantity * (option.volume as number) >= 1000 &&
      option.unit === 'g'
        ? 'kg'
        : option.unit}{' '}
      {/* this is the text bit ex: (2 x 2.5kg) */}
      {(option.unit === 'g' || option.unit === 'kg') &&
        `(${pricing.minimumQuantity} x ${option.volume as number}${
          option.unit
        })`}
      {/* calculate the price */} {packageDescription} ={' '}
      {getBulkPricingByType({
        pricing,
        option,
        bulkBuyView,
        servingSize,
        packageDescription,
      })}
    </li>
  );
}

function CapsulesBulkPriceItem({
  pricing,
  option,
  packageDescription,
  servingSize,
  bulkBuyView,
}: {
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment;
  option: Option;
  packageDescription: string;
  servingSize: number;
  bulkBuyView: number;
}) {
  return (
    <li className="m-0 p-0">
      {pricing.minimumQuantity}
      {pricing.maximumQuantity
        ? ` to ${pricing.maximumQuantity} `
        : ` x ${option.volume} `}
      {/* calculate the price */}
      {/* remove 's' from 'capsules' */}
      {packageDescription.slice(0, -1)} containers ={' '}
      {getBulkPricingByType({ pricing, option, bulkBuyView, servingSize })}
    </li>
  );
}

function PacksBulkPriceItem({
  pricing,
  option,
  packageDescription,
  servingSize,
  bulkBuyView,
}: {
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment;
  option: Option;
  packageDescription: string;
  servingSize: number;
  bulkBuyView: number;
}) {
  return (
    <li className="m-0 p-0">
      {pricing.minimumQuantity}
      {pricing.maximumQuantity
        ? ` to ${pricing.maximumQuantity} `
        : ` x ${option.volume} `}
      {/* calculate the price */}
      {packageDescription} ={' '}
      {getBulkPricingByType({ pricing, option, bulkBuyView, servingSize })}
    </li>
  );
}

function ShakersBulkPriceItem({
  pricing,
  option,
  packageDescription,
  bulkBuyView,
}: {
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment;
  option: Option;
  packageDescription: string;
  bulkBuyView: number;
}) {
  return (
    <li className="m-0 p-0">
      {pricing.minimumQuantity * (option.volume as number)} {option.unit}
      {/* calculate the price */}
      {packageDescription} ={' '}
      {getBulkPricingByType({ pricing, option, bulkBuyView })}
    </li>
  );
}

function ShirtsBulkPriceItem({
  pricing,
  option,
  packageDescription,
  bulkBuyView,
}: {
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment;
  option: Option;
  packageDescription: string;
  bulkBuyView: number;
}) {
  return (
    <li className="m-0 p-0">
      {pricing.minimumQuantity * (option.volume as number)} {option.unit}
      {/* calculate the price */}
      {packageDescription} ={' '}
      {getBulkPricingByType({ pricing, option, bulkBuyView })}
    </li>
  );
}

// get the correct bulk price for each type of package description
function BulkPriceItem({
  pricing,
  option,
  packageDescription,
  servingSize, //assuming bags and packs and capsules will have servingSize
  bulkBuyView,
}: {
  pricing: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment;
  option: Option;
  packageDescription: string;
  servingSize: number;
  bulkBuyView: number;
}) {
  switch (packageDescription) {
    case PackageDescription.Capsules:
      return (
        <CapsulesBulkPriceItem
          pricing={pricing}
          option={option}
          servingSize={servingSize}
          packageDescription={packageDescription}
          bulkBuyView={bulkBuyView}
        />
      );
    case PackageDescription.Shakers:
      return (
        <ShakersBulkPriceItem
          pricing={pricing}
          option={option}
          packageDescription={packageDescription}
          bulkBuyView={bulkBuyView}
        />
      );
    case PackageDescription.Packs:
      return (
        <PacksBulkPriceItem
          pricing={pricing}
          option={option}
          packageDescription={packageDescription}
          servingSize={servingSize}
          bulkBuyView={bulkBuyView}
        />
      );
    case PackageDescription.Shirts:
      return (
        <ShirtsBulkPriceItem
          pricing={pricing}
          option={option}
          packageDescription={packageDescription}
          bulkBuyView={bulkBuyView}
        />
      );
    default:
      return (
        <BagsBulkPriceItem
          pricing={pricing}
          option={option}
          packageDescription={packageDescription}
          servingSize={servingSize}
          bulkBuyView={bulkBuyView}
        />
      );
  }
}

export function BulkBuy({
  bulkPricing,
  variants,
  packageDescription,
  maxPurchaseQty,
  servingSize,
  hasFlavours,
  title,
}: {
  bulkPricing: (
    | BulkPricingPrice
    | BulkPricingPercent
    | BulkPricingAdjustment
  )[];
  variants: Variant[];
  packageDescription: string;
  maxPurchaseQty: number;
  servingSize: number;
  hasFlavours: boolean;
  title: string;
}): ReactElement {
  // set the initial bulk buy view
  const [bulkBuyView, setBulkBuyView] = useState<number>(
    BulkBuyView.PricePerUnit
  );

  // find the default bulk view in local storage, if not existed use PricePerUnit
  const setDefaultBulkBuyView = async () => {
    if (typeof window !== 'undefined') {
      try {
        const stringBulkBuyView = await getIndexedDBStoreData<string>(
          Stores.keyvaluepairs,
          'bulkBuyView'
        );
        if (stringBulkBuyView) {
          let localBulkBuyView = parseInt(stringBulkBuyView, 10);

          // check if the local bulk view is valid for the current product
          // use default view if not valid
          if (localBulkBuyView === BulkBuyView.PricePerServe && !servingSize) {
            localBulkBuyView = BulkBuyView.PricePerUnit;
          }

          setBulkBuyView(localBulkBuyView);
        }
      } catch (err) {
        logBreadcrumb({
          category: 'IndexedDB',
          message: `Tried to get bulkBuyView on product`,
          level: 'error',
        });
      }
    }
  };

  useEffect(() => {
    setDefaultBulkBuyView();
  }, []);

  // If no bulk pricing, at product or variant level, return no elements
  if (
    bulkPricing.length === 0 &&
    variants.map((v) => v.pricing.bulkPricing).flat().length === 0
  )
    return <></>;

  // Remove bulk pricing above max purchase
  if (maxPurchaseQty > 0) {
    bulkPricing.forEach((p, i, arr) => {
      if (p.minimumQuantity > maxPurchaseQty) {
        // can't hit min purchase so remove it
        arr.splice(i, 1);
      }
    });
  }

  /* 
    Go through the variants and reduce them to the price, volume, and unit (e.g. kg, g etc)
    This is necessary because not all variants are the same price
  */
  const { result: options } = variants.reduce(
    (acc, v) => {
      const packageSize = v.options.filter(
        (o) => o.displayName === 'Package Size'
      )[0]?.label;

      // If no package size variants, add price still (shakers etc)
      if (!packageSize) {
        if (acc.added.includes(`${v.pricing.price.value}`)) return acc;
        const option: Option = {
          price: v.pricing.price.value * 100,
        };
        acc.added.push(`${v.pricing.price.value}`);
        acc.result.push(option);
        return acc;
      }

      // Return if already got this combo
      if (acc.added.includes(`${v.pricing.price.value}${packageSize}`))
        return acc;

      // Split out package size to volume and unit
      const parts = packageSize.match(/^[\d.]+|[a-zA-Z]+/g);
      const volume = Number(parts?.[0] as string);
      const unit = (parts?.[1] as string).toLowerCase();

      // Add it to options
      acc.added.push(`${v.pricing.price.value}${packageSize}`);
      const option: Option = {
        price: v.pricing.price.value * 100,
        volume,
        unit,
      };
      if (v.pricing.bulkPricing.length > 0)
        option.bulkPricing = v.pricing.bulkPricing;
      acc.result.push(option); // price coming from BC so in dollars not cents
      return acc;
    },
    { added: [], result: [] } as {
      added: string[];
      result: Option[];
    }
  );

  const getPricePerServeViewTitle = (servingSize, packageDescription) => {
    if (packageDescription === PackageDescription.Bags) {
      return `Price Per Serve ${servingSize ? `(${servingSize}g)` : ''}`;
    }
    if (packageDescription === PackageDescription.Capsules) {
      return `Price Per Serve ${
        servingSize ? `(${servingSize} capsules)` : ''
      }`;
    }
    return `Price Per Serve`;
  };

  // get the title for the bulk buy view when switching between views
  const getViewTitle = ({
    bulkBuyView,
    packageDescription,
    servingSize,
  }): string => {
    // default case for product which doesn't have package description
    let unit = 'units';
    if (packageDescription && packageDescription !== 'capsules')
      unit = packageDescription;

    switch (bulkBuyView) {
      case BulkBuyView.TotalPriceAllUnits:
        return 'Total Price';
      case BulkBuyView.PricePerServe:
        return getPricePerServeViewTitle(servingSize, packageDescription);
      case BulkBuyView.DiscountPerUnit:
        return `Discount Per ${CapitaliseSentence(unit.slice(0, -1))}`;
      case BulkBuyView.TotalDiscountAllUnits:
        return 'Total Discount';
      case BulkBuyView.PercentageSaving:
        return 'Discount Percentage';
      default:
        return `Price Per ${CapitaliseSentence(unit.slice(0, -1))}`;
    }
  };

  const switchBulkBuyView = async (): Promise<void> => {
    let nextView;
    if (bulkBuyView < BulkBuyView.PercentageSaving) {
      nextView = bulkBuyView + 1;
      // skip price per serve if servingSize doesn't exist
      if (nextView === BulkBuyView.PricePerServe && !servingSize)
        nextView = bulkBuyView + 2;
    } else {
      nextView = BulkBuyView.PricePerUnit;
    }

    setBulkBuyView(nextView);
    try {
      await updateIndexedDBData(Stores.keyvaluepairs, 'bulkBuyView', nextView);
    } catch {
      logBreadcrumb({
        category: 'IndexedDB',
        message: `Tried to set bulkBuyView`,
        level: 'error',
      });
    }
  };

  if (options.length === 0) return <></>;

  // shakers don't have unit
  if (!options[0].unit) {
    return (
      <div className="relative pb-5 pt-2.5">
        <p className="m-0 pb-2 text-sm">
          Save on {title} with multibuy discounts. Add{' '}
          {bulkPricing[0].minimumQuantity} or more {packageDescription}{' '}
          {hasFlavours ? 'of any flavour' : ''} to your cart and start saving.
        </p>
        <div className="flex items-center justify-between">
          <h3 className="m-0 py-0 text-left text-base font-bold text-black dark:text-white">
            {getViewTitle({ bulkBuyView, packageDescription, servingSize })}
          </h3>
          <Button
            onClick={switchBulkBuyView}
            type={ButtonType.button}
            buttonStyle="text"
            className="h-fit"
          >
            Toggle View
          </Button>
        </div>
        {bulkPricing.map((bulkPrice, idx) => (
          <ul
            key={`no-unit-pricing-${idx}`}
            className="list-inside list-disc gap-2.5 text-sm sm:gap-5"
          >
            <li className="m-0 p-0">
              {bulkPrice.minimumQuantity}
              {bulkPrice.maximumQuantity
                ? ` - ${bulkPrice.maximumQuantity}`
                : ''}{' '}
              {packageDescription} ={' '}
              {getBulkPricingByType({
                pricing: bulkPrice,
                option: options[0],
                bulkBuyView,
                servingSize,
              })}
            </li>
          </ul>
        ))}
      </div>
    );
  }

  // Work out min quantity
  let minQuantity = bulkPricing[0]?.minimumQuantity;
  if (!minQuantity) {
    minQuantity = options.reduce(
      (acc, curr) => {
        if (curr.bulkPricing && curr.bulkPricing.length > 0) {
          acc.push(curr.bulkPricing[0]);
        }
        return acc;
      },
      [] as Array<BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment>
    )[0].minimumQuantity;
  }

  // Styled item is the first, default pricing
  // BulkPriceItem is with bulk discounts applied
  return (
    <div className="relative pb-5 pt-2.5">
      <p className="m-0 pb-2 text-sm">
        Save on {title} with multibuy discounts. Add {minQuantity} or more{' '}
        {packageDescription} {hasFlavours ? 'of any flavour' : ''} to your cart
        and start saving.
      </p>
      <div className="flex items-center justify-between">
        <h3 className="m-0 py-0 text-left text-base font-bold text-black dark:text-white">
          {getViewTitle({ bulkBuyView, packageDescription, servingSize })}
        </h3>
        <Button
          onClick={switchBulkBuyView}
          type={ButtonType.button}
          buttonStyle="text"
          className="h-fit"
        >
          Toggle View
        </Button>
      </div>
      <div className="grid grid-cols-1">
        {options.map((option, idx) => (
          <ul
            key={`pricing-${idx}`}
            className="list-inside list-disc gap-2.5 text-sm sm:gap-5"
          >
            {/* The first item is the standard price, not bulk price */}
            {getStandardPricing(
              bulkBuyView,
              option,
              servingSize,
              packageDescription
            )}

            {/* Start calculating bulk price */}
            {/* Product bulk pricing */}
            {bulkPricing.length > 0 &&
              bulkPricing.map((d, i) => (
                <BulkPriceItem
                  bulkBuyView={bulkBuyView}
                  pricing={d}
                  option={option}
                  packageDescription={packageDescription}
                  servingSize={servingSize}
                  key={`bulkbuy${i}`}
                />
              ))}
            {/* SKU bulk pricing */}
            {bulkPricing.length === 0 &&
              option.bulkPricing &&
              option.bulkPricing.map((d, i) => (
                <BulkPriceItem
                  bulkBuyView={bulkBuyView}
                  pricing={d}
                  option={option}
                  packageDescription={packageDescription}
                  servingSize={servingSize}
                  key={`bulkbuy${i}`}
                />
              ))}
          </ul>
        ))}
      </div>
    </div>
  );
}
