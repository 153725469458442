import { ReactElement, useContext } from 'react';

import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { CustomImage } from '@components/Image';
import { USP } from '@interfaces/Sanity';

export function USPComponent({ usps }: { usps: USP[] }): ReactElement {
  const {
    displayDarkMode: [darkModeIsOn],
  } = useContext<UIContextInterface>(UIContext);

  return (
    <div className="flex w-full justify-between gap-2">
      {usps.map((usp, i) => (
        <div
          key={`usp-${i}`}
          className={`flex-1 flex-col items-center ${i === 3 ? 'hidden sm:flex' : 'flex'}`}
        >
          <CustomImage
            src={darkModeIsOn ? usp.imageDarkMode : usp.image}
            alt={usp.altText}
            width={80}
            height={80}
            usePlaceholder={false}
          />
          <span className="text-center text-xs font-bold uppercase">
            {usp.text}
          </span>
        </div>
      ))}
    </div>
  );
}
