import React, { ReactElement } from 'react';
import { twJoin } from 'tailwind-merge';

import { Pricing } from '@interfaces/Product';

export function ProductPrice({
  basePrice,
  price,
}: {
  basePrice: number;
  price: number;
}): ReactElement {
  if (basePrice === price) {
    return (
      <span
        className={twJoin([
          'price',
          'text-4xl font-bold text-black dark:text-white',
        ])}
      >
        ${price.toFixed(2)}
      </span>
    );
  }
  return (
    <div className="flex flex-col mb-2">
      <span className='flex gap-1'>
        <span>Usual price</span>
        <span className="relative text-base before:absolute before:left-[-5%] before:top-[45%] before:mx-auto before:my-0 before:w-[110%] before:skew-y-[-10deg] before:border-0 before:border-t-2 before:border-solid before:border-t-orange before:opacity-60 before:content-['']">
          ${basePrice.toFixed(2)}
        </span>
      </span>
      <span
        className={twJoin(['price', 'font-bold text-black dark:text-white text-4xl my-2'])}
      >
        ${price.toFixed(2)}
      </span>
      <span className="font-primary text-base italic">
        You save ${(basePrice - price).toFixed(2)}
      </span>
    </div>
  );
}

export function ProductPriceRange({
  minPrice,
  maxPrice,
}: {
  minPrice: number;
  maxPrice: number;
}): ReactElement {
  return (
    <div className="text-4xl font-bold text-black dark:text-white">
      <span className="mx-1 my-0">${minPrice.toFixed(2)} </span>-
      <span className="mx-1 my-0">${maxPrice.toFixed(2)}</span>
    </div>
  );
}

export function ProductPricing({
  pricing,
}: {
  pricing: Pricing;
}): ReactElement {
  return pricing.priceRange.min.value === pricing.priceRange.max.value ? (
    <ProductPrice
      basePrice={
        pricing.basePrice ? pricing.basePrice.value : pricing.price.value
      }
      price={pricing.price.value}
    />
  ) : (
    <ProductPriceRange
      minPrice={pricing.priceRange.min.value}
      maxPrice={pricing.priceRange.max.value}
    />
  );
}
